.header {
    text-align: center;
  }
.info-bg {
    background-color: rgba(21, 171, 255, 0.13);
}
.submit-bg {
    background-color: rgba(251, 245, 231, 0.49);
}
.bg {
    width: 100%;
    height: 100vh;
    background-color: rgba(251, 245, 231, 0.49);
}
.title {
    width: 100%;
    text-align: center;
    font-size: 16rpx;
    font-weight: 400;
    color: red;
}